import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const ProjectPostInfo = ({ node }) => {
  const { title, path, acf } = node
  if (
    acf &&
    !!acf.project_single_post &&
    !!acf.project_single_post.our_project
  ) {
    const { project_single_post } = acf
    const { our_project: post } = project_single_post
    return (
      <>
        <Img
          fluid={post.our_project_image.localFile.childImageSharp.fluid}
          alt={title}
          style={{ width: '300px', height: '380px' }}
          className="img-fluid mx-auto mx-sm-0"
        />
        <h3 className="h4 font-weight-bold mt-3 text-uppercase">{title}</h3>
        <p>{post.our_project_description}</p>
        <div className="mt-auto">
          <Link
            to={`${path}`}
            className="btn btn-sm btn-outline-warning text-uppercase"
          >
            See more
          </Link>
        </div>
      </>
    )
  }
  return <></>
}

export default ProjectPostInfo
