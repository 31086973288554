import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import posed from 'react-pose'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Calendar from '../components/calendar'
import Image from '../components/image'
import BannerImpact from '../components/banner-impact'
import ProjectPost from '../components/project-post'
import ProjectHeroImage from '../components/project-hero-image'

const FadeUp = posed.div({
  exit: {
    y: '100px',
    opacity: 0,
  },
  enter: {
    y: '0%',
    opacity: 1,
    beforeChildren: true,
    staggerChildren: 50,
    transition: { duration: 1000 },
  },
})

const ProjectsTemplates = ({ data }) => {
  const { two_main_projects, individual_projects } = data
  const { edges: projectList } = individual_projects
  const [visibleModal, setVisibleModal] = useState(false)
  const handleModal = isVisible => {
    document.body.className = isVisible ? 'modal-open' : ''
    setVisibleModal(isVisible)
  }
  return (
    <>
      <Layout>
        <SEO title="Projects" />

        <section className="text-white position-relative text-center pb-3 pb-sm-0">
          <Image
            filename="projects/hero-image.jpg"
            className="img-fluid"
            style={{ minHeight: '300px' }}
          />
          <FadeUp
            initialPose="exit"
            pose="enter"
            className="container make-your-impact-box py-3 py-sm-5"
          >
            <h1 className="display-4 font-weight-bold mb-3">OUR PROJECTS</h1>
            <p className="h5 mb-2">
              Here are the large scale projects we work to support and catalyse.
            </p>
            <button
              type="button"
              className="btn btn-sm btn-success text-uppercase mt-4"
              onClick={() => handleModal(true)}
            >
              PROJECT CALENDAR
            </button>
          </FadeUp>
        </section>

        <div className="container py-4">
          <p className="h5 text-center my-4">
            From building schools, to raising awareness around shark ﬁshing, to
            beach clean ups. We aim to create equal parts community and positive
            change.
          </p>
        </div>
        <ProjectHeroImage {...two_main_projects} />
        <div className="container py-4">
          <p className="h5 text-center my-4">
            Through education, we can make efficient and efficient action in
            truly bettering the world. There is no time to waste; knowledge is
            our biggest asset and through these projects we showcase how
            knowledge can manifest into saving our planet.{' '}
          </p>
        </div>

        <section className="bg-green py-5 text-center text-sm-left">
          <div className="container text-white">
            <h2 className="mt-5">Our Collaborators</h2>
            <div className="row">
              <div className="d-flex flex-column col-sm-6 col-lg-4 mt-5 mt-sm-4">
                <Image
                  filename="projects/kashmir-postel.jpg"
                  style={{ width: '300px', height: '380px' }}
                  alt="Kashmir Postel"
                  className="img-fluid mx-auto mx-sm-0"
                />
                <h3 className="h5 font-weight-bold mt-3 mb-1">
                  KASHMIR POSTEL
                </h3>
                <h4 className="h6">CREATIVITY FOR CHANGE</h4>
                <p>
                  Kashmir uses her creative medium of perfomance to educate
                  children and raise a generation of eco-warriors. See below for
                  education on how you can have the same impact.
                </p>
                <div className="mt-auto">
                  <Link
                    to="/video/PLvlX7nCVnPQelu9FxDVnV-8NpQC8ZpZey/"
                    className="btn btn-sm btn-outline-warning text-uppercase"
                  >
                    See more
                  </Link>
                </div>
              </div>
              <div className="d-flex flex-column col-sm-6 col-lg-4 mt-5 mt-sm-4">
                <Image
                  filename="projects/david-goulet.jpg"
                  style={{ width: '300px', height: '380px' }}
                  alt="David Goulet"
                  className="img-fluid mx-auto mx-sm-0"
                />
                <h3 className="h5 font-weight-bold mt-3 mb-1">DAVID GOULET</h3>
                <h4 className="h6">PLAYING WITH MAGIC</h4>
                <p>
                  Yoga, breath and everything in between. Dive into ‘Energi
                  Guru’ David Goulet’s mind as he summaries 50+ years of yoga
                  study into short, actionable steps.
                </p>
                <div className="mt-auto">
                  <Link
                    to="/video/PLvlX7nCVnPQcdtZBvw8YIBMZxIojyvv5C/"
                    className="btn btn-sm btn-outline-warning text-uppercase"
                  >
                    See more
                  </Link>
                </div>
              </div>
            </div>
            <h2 className="mt-5">Our Projects</h2>
            <div className="row">
              {projectList.map((project, index) => (
                <div
                  className="d-flex flex-column col-sm-6 col-lg-4 mt-5 mt-sm-4"
                  key={index}
                >
                  <ProjectPost {...project} />
                </div>
              ))}
            </div>
          </div>
        </section>
        <div className="container text-center py-4">
          <p className="h5 my-4">Get involved in our upcoming projects!</p>
          <button
            type="button"
            className="btn btn-sm btn-success text-uppercase"
            onClick={() => handleModal(true)}
          >
            PROJECT CALENDAR
          </button>
        </div>
        <BannerImpact className="pb-sm-0 mt-1" />
        <Calendar
          showModal={visibleModal}
          handleClose={() => handleModal(false)}
          title="Project Calendar"
        />
      </Layout>
    </>
  )
}
export default ProjectsTemplates

export const pageQuery = graphql`
  query($id: Int!) {
    individual_projects: allWordpressPost(
      sort: { fields: [date] }
      filter: { categories: { elemMatch: { name: { eq: "Projects" } } } }
    ) {
      edges {
        node {
          title
          path
          categories {
            name
            slug
          }
          acf {
            project_single_post {
              our_project {
                our_project_image {
                  localFile {
                    childImageSharp {
                       fluid(quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                our_project_description
              }
            }
          }
        }
      }
    }
    two_main_projects: wordpressPage(wordpress_id: { eq: $id }) {
      title
      path
      acf {
        main_projects {
          title_project_1
          description_project_1
          image_project_1 {
            localFile {
              childImageSharp {
                 fluid(quality: 80) {
                  originalName
                }
              }
            }
          }
          title_project_2
          description_project_2
          image_project_2 {
            localFile {
              childImageSharp {
                 fluid(quality: 80) {
                  originalName
                }
              }
            }
          }
          button_project_2 {
            post_name
          }
          button_project_1 {
            post_name
          }
        }
      }
    }
  }
`
