import React from 'react'
import { Link } from 'gatsby'
import Image from './image'

const ProjectHeroImage = ({ path, acf }) => {
  const { main_projects } = acf

  if (
    acf &&
    !!acf.main_projects &&
    !!main_projects.title_project_1 &&
    !!main_projects.description_project_1 &&
    !!main_projects.image_project_1 &&
    !!main_projects.button_project_1 &&
    !!main_projects.title_project_2 &&
    !!main_projects.description_project_2 &&
    !!main_projects.image_project_2 &&
    !!main_projects.button_project_2
  ) {
    const {
      title_project_1,
      description_project_1,
      image_project_1,
      button_project_1,
      title_project_2,
      description_project_2,
      image_project_2,
      button_project_2,
    } = main_projects
    return (
      <section className="row p-0 m-0">
        <div className="col-lg p-0">
          <Image
            filename={
              image_project_1.localFile.childImageSharp.fluid.originalName
            }
            className="img-fluid img-main-hero"
            alt={title_project_1}
          />
          <div className="get-educated-box px-3 py-4 py-sm-5 px-sm-0">
            <h2 className="mb-3 font-weight-bold text-uppercase">
              {title_project_1}
            </h2>
            <p>{description_project_1}</p>
            {button_project_1.post_name && (
              <div className="text-center btn-absolute-bottom w-100 mb-3 px-3 px-sm-0">
                <Link
                  to={`${path}${button_project_1.post_name}/`}
                  className="btn btn-lg btn-success btn-block text-uppercase"
                >
                  See project
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg p-0">
          <Image
            filename={
              image_project_2.localFile.childImageSharp.fluid.originalName
            }
            className="img-fluid img-main-hero"
            alt={title_project_2}
          />
          <div className="get-educated-box px-3 py-4 py-sm-5 px-sm-0">
            <h2 className="mb-3 font-weight-bold text-uppercase">
              {title_project_2}
            </h2>
            <p>{description_project_2}</p>
            {button_project_2.post_name && (
              <div className="text-center btn-absolute-bottom w-100 mb-3 px-3 px-sm-0">
                <Link
                  to={`${path}${button_project_2.post_name}/`}
                  className="btn btn-lg btn-success btn-block text-uppercase"
                >
                  See project
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
  return <></>
}

export default ProjectHeroImage
