import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Image from './image'

const BannerImpact = ({ className }) => (
  <section
    className={`bg-green text-white position-relative text-center${className &&
      ` ${className}`}`}
  >
    <Image
      filename="general/make-your-impact.jpg"
      className="img-fluid"
      style={{ minHeight: '500px' }}
    />
    <div className="container make-your-impact-box py-3 py-sm-5">
      <div className="display-4 font-weight-bold mb-3">MAKE YOUR IMPACT</div>
      <p className="h5 mb-2">
        We’re always looking for new changemakers to support.
      </p>
      <p className="h5">
        Get in touch to see how we can create more impact together.
      </p>
      <div className="text-center btn-absolute-bottom w-100 mb-3 mb-sm-5">
        <Link to="/make-your-impact" className="btn btn-success">
          TAKE ACTION
        </Link>
      </div>
    </div>
  </section>
)
BannerImpact.propTypes = {
  className: PropTypes.string,
}

BannerImpact.defaultProps = {
  className: ``,
}
export default BannerImpact
